<template>
  <div class="container" id="container">

    <van-search class="self-search" v-model="keyWorld" show-action placeholder="请输入搜索关键词">
      <template #action>
        <div @click="search">搜索</div>
      </template>
    </van-search>

    <!-- 导航 -->
    <ul class="home-nav">
      <li class="home-nav-item" @click="toBVue('/meet')">
          <i class="icon-meet"></i>
          <p>会议</p>
      </li>
      <li class="home-nav-item" @click="toBVue('/Live')">
        <i class="icon-live"></i>
        <p>直播</p>
      </li>
      <li class="home-nav-item" @click="toBVue('/course')">
        <i class="icon-course"></i>
        <p>课程</p>
      </li>
      <li class="home-nav-item" @click="toBVue('/medicine')">
        <i class="icon-circle"></i>
        <p>智库</p>
      </li>
    </ul>

    <!-- banner -->
    <div class="home-banner">
      <van-swipe :autoplay="3000" class="self-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.cover" alt="" @click="toBanner(item.link_type, item.link_url_h5)">
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 直播预告 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/Live')">
        <p>直播预告</p>
        <p class="more">更多 ></p>
      </div>
      <div>
        <div class="live-scroll">
          <div v-for="(item, index) in liveTrailerList" :key="index" id="item.id" class="gray-card"  @click="toBVueInfo('/liveDetail', item.id)">
            <span class="card-horn" v-if="item.credit">学分</span>
            <div class="card-title">
              <div class="card-title-time">
                <div class="tag">时间</div>
                <p class="time">{{item.timeShow}}</p>
                <p>{{item.num}}人报名</p>
              </div>
              <p>主讲：{{item.speaker}}</p>
            </div>
            <p class="card-desc">{{item.title}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div class="home-banner">
      <van-swipe class="self-swipe" indicator-color="white">
        <van-swipe-item v-for="(item, index) in bannerListOne" :key="index">
          <img :src="item.cover" alt="" @click="toBanner(item.link_type, item.link_url_h5)">
        </van-swipe-item>
      </van-swipe>
    </div>

     <!-- 会议精选 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/meet')">
        <p>会议精选</p>
        <p class="more">更多 ></p>
      </div>
      <div class="meet-list">
        <div v-for="(item, index) in meetingList" :key="index" class="meet-card" @click="toBVueInfo('/meetDetail', item.id)">
          <img :src="item.cover" alt="">
          <p class="ellipsis line2">{{item.title}}</p>
        </div>
      </div>
    </div>

    <!-- 热门课程 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/hotCourse')">
        <p>热门课程</p>
        <p class="more">更多 ></p>
      </div>
      <div class="course-list">
        <div v-for="(item, index) in courseHotList" :key="index" bindtap="goCourseDetail" class="course-card" @click="toBVueInfo('/courseDetail', item.id)">
          <img :src="item.cover" alt="">
          <p>{{item.title}}</p>
          <p class="teacher">{{item.author}} {{item.author_title}}</p>
        </div>
      </div>
    </div>

    <!-- 医学智库 -->
    <div class="home-model">
      <div class="model-title" @click="toBVue('/medicine')">
        <p>医学智库</p>
        <p class="more">更多 ></p>
      </div>
      <div class="news-list">
        <div v-for="(item, index) in articleList" :key="index" class="news-card" @click="toBVueInfo('/medicineDetail', item.id)">
          <div class="news-desc">
            <p class="news-title">{{item.title}}</p>
            <div class="news-span">
              <p>{{item.time}}</p>
              <p>{{item.view_num}}浏览</p>
            </div>
          </div>
          <div class="list-img-box">
            <img :src="item.cover" alt="">
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";

export default {
  name: "Index",
  components: {
  },
  data() {
    return {
      liveTrailerList: [],
      meetingList: [],
      courseHotList: [],
      articleList: [],
      bannerListOne: [],
      bannerList: [],
      keyWorld: '',
    };
  },
  computed: {
    
  },
  watch: {
  },
  created() {
    this.banner(1)
    this.banner(2)
    this.liveTrailer()
    this.meeting()
    this.courseHot()
    this.article()
  },
  mounted() {
  },
  methods: {
    search() {
      const keyword = this.keyWorld
      this.$router.push({path: '/searchResult', query: {keyword: keyword}})
    },
    //banner
    banner(module) {
      let that = this
      this.$api.BANNER_GET({'module': module}).then(res => {
        if (res.length == 0) {
          return false
        }
        res.forEach((item) => {
          if (module == 1) {
            that.bannerList.push({
              id: item.id,
              cover: item.file_path ? item.file_path : '',
              link_type: item.link_type,
              link_url_h5: item.link_url_h5
            })
          } else {
            that.bannerListOne.push({
              id: item.id,
              cover: item.file_path ? item.file_path : '',
              link_type: item.link_type,
              link_url_h5: item.link_url_h5
            })
          }
        })
      })
    },
    //直播预告
    liveTrailer() {
      let that = this
      this.$api.RECOMMEND_CUSTOM_GET({
        'action':'index_live_trailer',
        'offset':0,
        'limit': 4
      }).then(res => {
        if (res.length == 0) {
          return false
        }
        res.forEach((item) => {
          that.liveTrailerList.push({
            id: item.id,
            title: item.title,
            speaker: item.speaker,
            credit: item.credit ? true : false,
            num: 0,
            cover: item.cover ? item.cover : '',
            timeShow: item.start_time + '-' +item.end_time
          })
        })
      })
    },
    //会议精选
    meeting() {
      let that = this
      this.$api.RECOMMEND_CUSTOM_GET({
        'action':'index_meeting',
        'offset':0,
        'limit': 2
      }).then(res => {
        if (res.length == 0) {
          return false
        }
        res.forEach((item) => {
          that.meetingList.push({
            id: item.id,
            title: item.title,
            cover: item.cover ? item.cover : '',
          })
        })
      })
    },
    //热门课程
    courseHot() {
      let that = this
      this.$api.RECOMMEND_CUSTOM_GET({
        'action':'home_course_hot',
        'offset':0,
        'limit': 2
      }).then(res => {
        if (res.length == 0) {
          return false
        }
        res.forEach((item) => {
          that.courseHotList.push({
            id: item.id,
            title: item.title,
            cover: item.cover ? item.cover : '',
            author: item.author,
            author_title: item.author_title
          })
        })
      })
    },
    //医学智库
    article() {
      let that = this
      this.$api.INDEX_ARTICLE_GET({}).then(res => {
        if (res.length === 0) {
          return false
        }
        res.forEach((item) => {
          that.articleList.push({
            id: item.id,
            title: item.title,
            cover: item.cover ? item.cover : '',
            view_num: item.view_num,
            time: item.create_time
          })
        })
      })
    },
    //跳转
    toBVue(toPath) {
      this.$router.push({path: toPath})
    },
    //进入详情
    toBVueInfo(toPath, toid) {
      this.$router.push({path: toPath, query: {id: toid}})
    },
    //banner
    toBanner(type, toid) {
      if (type == 1) {
        return false
      } else if (type == 2) {
        this.toUrl = '/liveDetail'
      } else if (type == 3) {
        this.toUrl = '/meetDetail'
      } else if (type == 4) {
        this.toUrl = '/courseDetail'
      } else if (type == 5) {
        this.toUrl = '/medicineDetail'
      } else if (type == 6 || type == 7 || type == 8) {
        this.toUrl = '/organDetail'
        let action = ''
        if (type == 6) {
          action = 'dynamic'
        } else if (type == 7) {
          action = 'science'
        } else if (type == 8) {
          action = 'activity'
        }
        this.$router.push({path: this.toUrl, query: {id: toid, action: action}})
        return false
      }
      this.$router.push({path: this.toUrl, query: {id: toid}})
    }
  }
}
</script>

<style lang="less" scoped>
.home-nav{
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top:24px;
  .home-nav-item{
    padding:0 10px;
    text-align: center;
  }
  i{
    display:block;
    width:44px;
    height:44px;
    background-repeat:no-repeat;
    background-size:44px auto;
  }
  .icon-meet{
    background-image:url(../../assets/images/index/icon-meet.png);
  }
  .icon-live{
    background-image:url(../../assets/images/index/icon-live.png);
  }
  .icon-course{
    background-image:url(../../assets/images/index/icon-course.png);
  }
  .icon-circle{
    background-image:url(../../assets/images/index/icon-circle.png);
  }
  p{
    font-size:12px;
    color:#000;
    padding-top:3px;
  }

}
.home-banner{
  margin-top:24px;
}
.self-swipe{
  img{
    width:100%;
    max-height:100%;
  }
}
.home-model{
  margin-top:27px;
}

</style>
